import { AnalysisItem } from '@/models/analysis-item';

const tableColumns = [
  {
    name: 'id',
    required: true,
    label: 'Código',
    align: 'left',
    field: (row: AnalysisItem): number | null => row.id,
    sortable: true,
  },
  {
    name: 'text',
    required: true,
    label: 'Nome',
    align: 'left',
    field: (row: AnalysisItem): string => row.text,
    sortable: true,
  },
  {
    name: 'unit',
    required: true,
    label: 'Unidade',
    align: 'left',
    field: (row: AnalysisItem): string => row.unit,
    sortable: false,
  },
  {
    name: 'group',
    required: false,
    label: 'Grupo',
    align: 'left',
    field: (row: AnalysisItem): string => row.group ?? '',
    sortable: false,
  },
  {
    name: 'hidden',
    required: true,
    label: 'Status',
    align: 'left',
    field: (row: AnalysisItem): string => (row.hidden ? 'Desativado' : 'Ativado'),
    sortable: false,
  },
  {
    name: 'actions',
    label: '',
    align: 'left',
    field: (row: AnalysisItem): AnalysisItem => row,
    sortable: false,
  },
];

export { tableColumns };

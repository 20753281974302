interface AnalysisItemOrder {
  id: number | null;
  order: number;
}

interface AnalysisItemOrderForm {
  items_order: AnalysisItemOrder[];
}

interface AnalysisItemOrderFromToRequest {
  items_order: { id: number; order: number }[];
}

const getEmptyAnalysisItemOrder = (): AnalysisItemOrderForm => ({
  items_order: [] as AnalysisItemOrder[],
});

const toRequestDataOrder = (formData: AnalysisItemOrderForm): AnalysisItemOrderFromToRequest => {
  return {
    items_order: formData.items_order.map((el) => ({
      id: el.id ?? 0,
      order: el.order,
    })),
  };
};

export { AnalysisItemOrder, AnalysisItemOrderForm, toRequestDataOrder, getEmptyAnalysisItemOrder };

import { Expose } from 'class-transformer';
import { Model } from './model';
import { Moment } from 'moment';

type AnalysisItemType = 'input' | 'auto' | 'select' | 'function';

enum AnalysisItemTypeString {
  input = 'Campo Input',
  auto = 'Campo Autocomplete',
  select = 'Campo Seletor',
  function = 'Função',
}

class AnalysisItem extends Model {
  @Expose()
  public identifier: string;

  @Expose()
  public text: string;

  @Expose()
  public unit: string;

  @Expose()
  public group: string | null;

  @Expose()
  public expression: string | null;

  @Expose()
  public hidden: boolean;

  @Expose()
  public type: AnalysisItemType;

  @Expose()
  public order: number;

  @Expose({ name: 'select_options' })
  public selectOptions: string[] | null;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment | null,
    identifier: string,
    text: string,
    unit: string,
    group: string,
    expression: string,
    hidden: boolean,
    type: AnalysisItemType,
    order: number,
    selectOptions: string[] | null
  ) {
    super(id, createdAt, updatedAt, deletedAt);
    this.identifier = identifier;
    this.text = text;
    this.unit = unit;
    this.group = group;
    this.expression = expression;
    this.hidden = hidden;
    this.type = type;
    this.order = order;
    this.selectOptions = selectOptions;
  }
}

export { AnalysisItem, AnalysisItemType, AnalysisItemTypeString };

import { AnalysisItem, AnalysisItemType, AnalysisItemTypeString } from '@/models/analysis-item';

interface AnalysisItemForm {
  identifier: string;
  text: string;
  unit: string;
  group: string | null;
  expression: string | null;
  hidden: boolean;
  type: AnalysisItemType;
  order: number;
  selectOptions: string[] | null;
  dadosTest: string | null;
}

interface AnalysisItemFormToRequest {
  identifier: string;
  text: string;
  unit: string;
  group: string | null;
  expression: string | null;
  hidden: boolean;
  type: AnalysisItemType;
  order: number;
  selectOptions: string[] | null;
}

interface AnalysisItemTypeOptions {
  label: string;
  value: AnalysisItemType;
}

const getEmptyAnalysisItemForm = (): AnalysisItemForm => ({
  identifier: '',
  text: '',
  unit: '',
  group: null,
  expression: null,
  hidden: false,
  type: 'input',
  order: 0,
  selectOptions: null,
  dadosTest: null,
});

const getFormByInstance = (analysisItem: AnalysisItem): AnalysisItemForm => ({
  identifier: analysisItem.identifier,
  text: analysisItem.text,
  unit: analysisItem.unit,
  group: analysisItem.group,
  expression: analysisItem.expression,
  hidden: analysisItem.hidden,
  type: analysisItem.type,
  order: analysisItem.order,
  selectOptions: analysisItem.selectOptions,
  dadosTest: null,
});

const toRequestData = (formData: AnalysisItemForm): AnalysisItemFormToRequest => {
  return {
    identifier: formData.identifier,
    text: formData.text,
    unit: formData.unit,
    group: formData.group,
    expression: formData.expression,
    hidden: formData.hidden,
    type: formData.type,
    order: formData.order,
    selectOptions: formData.selectOptions,
  };
};

const getAnalysisItemTypeOptions = (): AnalysisItemTypeOptions[] =>
  Object.keys(AnalysisItemTypeString).map((key) => ({
    label: AnalysisItemTypeString[key as AnalysisItemType],
    value: key as AnalysisItemType,
  }));

export {
  getEmptyAnalysisItemForm,
  getFormByInstance,
  getAnalysisItemTypeOptions,
  toRequestData,
  AnalysisItemForm,
};
